<template>
  <div class="ujjk-cards">
    <div class="ujjk-card" v-for="(member, index) in members" :key=index>
      <div class="ujjk-card-title">Uppsala Ju-jutsuklubb</div>
      <div class="ujjk-card-subtitle">Medlemskort</div>
      <p>för {{ member.name }}</p>
      <p>Medlemsavgift för {{ member.period }} erlagd med {{ member.amount }} kr</p>
      <p class="ujjk-card-number">Nr: {{ member.number }}</p>
      <p class="ujjk-card-sign-label">för kassören</p>
    </div>
  </div>
</template>

<script>
  export default {
    mounted() {
      this.fetchMembersWithFees()
    },
    data() {
      return {
        members: []
      }
    },
    methods: {
      /* original (and seemingly not working) function from vuex store
      async fetchMembersWithFees({ commit }) {
        try  {
          let feesRef = await db.collection('fees')
              .where('period', '==', '2021')
              .where('type', '==', 'membership')
              .get()

          let membs = []
          for (const fee of feesRef.docs) {
            let memberId = fee.data().memberId
            let memberRef = await db.collection('members').doc(memberId).get()
            let member = memberRef.data()
            let paymentsRef = await db.collection('payments').where('memberId', '==', memberId).get()
            let payments = paymentsRef.docs.map(d => d.data()).reduce((acc, p) => {
              return acc + `${p.date}: ${p.amount} `
            }, '')

            membs.push({
              feeId: fee.id,
              ...fee.data(),
              name: `${member.firstName} ${member.lastName}`,
              payments
            })
          }
          commit('SET_MEMBERS', membs);
        } catch (error) {
          console.log('knas: ', error)
        }
      } */

      async fetchMembersWithFees() {
      }
    }
  }

</script>

<style>
  @media print {
      html, body {
          width: 210mm;
          height: 297mm;
      }
  }

      .ujjk-cards {
          //width: 100%;
          column-width: 102mm;
          column-gap: 3mm;
          break-before: page;
          /* columns: 105mm 2; */
      }
      .ujjk-card {
          font-family: Helvetica, sans-serif;
          font-size: 12pt;
          margin-left: 2mm;
          margin-right: 2mm;
          margin-bottom: 3mm;
          padding-left: 3mm;
          padding-right: 3mm;
          border-style: solid;
          border-width: 1.2mm;
          border-radius: 6mm;
          width: 88mm;
          height: 62mm;
          background-image: url('~@/assets/ujjk_logo_red.png');
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 64mm;
      }

      .ujjk-card-title {
          margin-top: 1mm;
          margin-bottom: 1mm;
          font-size: 22pt;
          font-weight: bold;
          text-align: center;
      }
      .ujjk-card-subtitle {
          margin-top: 0;
          margin-bottom: 5mm;
          font-size: 16pt;
          font-weight: normal;
          text-align: center;
      }
      .ujjk-card-number {
          margin-top: 8mm;
          font-size: 16pt;
          font-weight: bold;
      }
      .ujjk-card-sign-label {
          font-size: 8pt;
          text-align: right;
          padding-right: 20mm;
          margin-top: 8mm;
      }
</style>
